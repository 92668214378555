body {
    margin: 0;
}

@font-face {
    font-family: Proxima Nova Regular;
    src: url(assets/fonts/ProximaNovaRegular.otf);
}

@font-face {
    font-family: Proxima Nova Bold;
    src: url(assets/fonts/ProximaNovaBold.otf);
}